"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var FeedFoodButton = /** @class */ (function (_super) {
    __extends(FeedFoodButton, _super);
    function FeedFoodButton(action) {
        var _this = _super.call(this) || this;
        _this._action = action;
        _this.addChild(_this.sprite);
        _this.interactive = true;
        _this.on("pointerdown", function () {
            action(this);
        });
        return _this;
    }
    Object.defineProperty(FeedFoodButton.prototype, "sprite", {
        get: function () {
            if (!this._sprite) {
                this._sprite = new PIXI.Sprite();
                this._sprite.width = 94;
                this._sprite.height = 94;
            }
            return this._sprite;
        },
        enumerable: false,
        configurable: true
    });
    FeedFoodButton.prototype.setTexture = function (texture) {
        this.sprite.texture = texture;
    };
    return FeedFoodButton;
}(PIXI.Container));
exports.default = FeedFoodButton;
