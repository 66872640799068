"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
require("pixi-spine");
var placeholder_slot_1 = require("../utilities/placeholder-slot");
var ProfileContainer = /** @class */ (function (_super) {
    __extends(ProfileContainer, _super);
    function ProfileContainer(resources) {
        var _this = _super.call(this) || this;
        _this._resources = resources;
        _this.spine.update(0);
        return _this;
    }
    Object.defineProperty(ProfileContainer.prototype, "spine", {
        get: function () {
            if (!this._spine) {
                this._spine = new PIXI.spine.Spine(this._resources.profile.spineData);
                this._spine.autoUpdate = false;
                this.addChild(this._spine);
                // Load slots
                this.dragonSlot;
            }
            return this._spine;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProfileContainer.prototype, "dragonSlot", {
        get: function () {
            if (!this._dragonSlot) {
                this._dragonSlot = (0, placeholder_slot_1.adaptPlaceholderSlot)("dragon", this.spine);
            }
            return this._dragonSlot;
        },
        enumerable: false,
        configurable: true
    });
    return ProfileContainer;
}(PIXI.Container));
exports.default = ProfileContainer;
