"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
var customize_1 = require("./customize");
var random_1 = require("../utilities/random");
var Gift = /** @class */ (function () {
    function Gift(categoryID, elementID, level) {
        this.categoryID = categoryID;
        this.elementID = elementID;
        this.level = level;
    }
    Gift.Compare = function (oldLevel, newLevel, unlocks) {
        var e_1, _a;
        var _b;
        if (newLevel <= oldLevel) {
            return null;
        }
        var remaining = {};
        try {
            for (var _c = __values(Object.keys(customize_1.CUSTOMIZE_CATEGORIES)), _d = _c.next(); !_d.done; _d = _c.next()) {
                var categoryID = _d.value;
                var category = customize_1.CUSTOMIZE_CATEGORIES[categoryID];
                var elementIDs = Object.keys(category.elements);
                var unlockedElementIDs = (_b = unlocks[categoryID]) !== null && _b !== void 0 ? _b : [];
                var remainingElementIDs = elementIDs.filter(function (elementID) { return !unlockedElementIDs.includes(elementID); });
                if (remainingElementIDs.length != 0) {
                    remaining[categoryID] = remainingElementIDs;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (Object.keys(remaining).length == 0) {
            return null;
        }
        var giftCategoryID = (0, random_1.randomValue)(Object.keys(remaining));
        return {
            categoryID: giftCategoryID,
            elementID: (0, random_1.randomValue)(remaining[giftCategoryID]),
            level: oldLevel + 1
        };
    };
    return Gift;
}());
exports.default = Gift;
