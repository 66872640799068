"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var State = /** @class */ (function () {
    function State() {
        this.stateMachine = null;
    }
    State.prototype.isValidTo = function (nextStateClass) {
        return nextStateClass != this.constructor;
    };
    State.prototype.didEnterFrom = function (previousState) { };
    State.prototype.willExitTo = function (nextState) { };
    State.prototype.update = function (deltaTime) { };
    return State;
}());
exports.default = State;
