"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
require("pixi-spine");
var placeholder_slot_1 = require("../utilities/placeholder-slot");
var BackdropContainer = /** @class */ (function (_super) {
    __extends(BackdropContainer, _super);
    function BackdropContainer(resources) {
        var _this = _super.call(this) || this;
        _this._resources = resources;
        return _this;
    }
    Object.defineProperty(BackdropContainer.prototype, "spine", {
        get: function () {
            if (!this._spine) {
                this._spine = new PIXI.spine.Spine(this._resources.backdrop.spineData);
                this._spine.state.setAnimation(0, "idle", true);
                this.addChild(this._spine);
                // Load slots
                this.dragonSlot;
                this.giftSlot;
            }
            return this._spine;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BackdropContainer.prototype, "dragonSlot", {
        get: function () {
            if (!this._dragonSlot) {
                this._dragonSlot = (0, placeholder_slot_1.adaptPlaceholderSlot)("dragon", this.spine);
            }
            return this._dragonSlot;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BackdropContainer.prototype, "giftSlot", {
        get: function () {
            if (!this._giftSlot) {
                this._giftSlot = (0, placeholder_slot_1.adaptPlaceholderSlot)("gift", this.spine);
            }
            return this._giftSlot;
        },
        enumerable: false,
        configurable: true
    });
    BackdropContainer.prototype.configureAppearance = function (backdrop) {
        if (backdrop == this._backdrop) {
            return;
        }
        this._backdrop = backdrop;
        var skin = new PIXI.spine.core.Skin("skin");
        skin.addSkin(this.spine.skeleton.data.findSkin(backdrop));
        this.spine.skeleton.setSkin(skin);
        this.spine.update(0);
    };
    return BackdropContainer;
}(PIXI.Container));
exports.default = BackdropContainer;
