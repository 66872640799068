"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.STACK_CONTAINER_ALIGNMENT = exports.STACK_CONTAINER_AXIS = void 0;
var STACK_CONTAINER_AXIS;
(function (STACK_CONTAINER_AXIS) {
    STACK_CONTAINER_AXIS["HORIZONTAL"] = "horizontal";
    STACK_CONTAINER_AXIS["VERTICAL"] = "vertical";
})(STACK_CONTAINER_AXIS = exports.STACK_CONTAINER_AXIS || (exports.STACK_CONTAINER_AXIS = {}));
;
var STACK_CONTAINER_ALIGNMENT;
(function (STACK_CONTAINER_ALIGNMENT) {
    STACK_CONTAINER_ALIGNMENT["LEADING"] = "leading";
    STACK_CONTAINER_ALIGNMENT["CENTER"] = "center";
    STACK_CONTAINER_ALIGNMENT["TRAILING"] = "trailing";
})(STACK_CONTAINER_ALIGNMENT = exports.STACK_CONTAINER_ALIGNMENT || (exports.STACK_CONTAINER_ALIGNMENT = {}));
;
var StackContainer = /** @class */ (function (_super) {
    __extends(StackContainer, _super);
    function StackContainer(arrangedChildren, options) {
        var _this = _super.call(this) || this;
        _this._calculateBounds = function () {
            this._bounds.addFrame(this.transform, 0, 0, this._minWidth, this._minHeight);
        };
        _this._axis = options.axis;
        _this._alignment = options.alignment;
        _this._spacing = options.spacing;
        _this._minWidth = options.minWidth;
        _this._minHeight = options.minHeight;
        _this.arrangedChildren = arrangedChildren;
        return _this;
    }
    Object.defineProperty(StackContainer.prototype, "arrangedChildren", {
        get: function () {
            return this._arrangedChildren;
        },
        set: function (value) {
            var e_1, _a, e_2, _b;
            if (this._arrangedChildren) {
                try {
                    for (var _c = __values(this._arrangedChildren), _d = _c.next(); !_d.done; _d = _c.next()) {
                        var oldChild = _d.value;
                        this.removeChild(oldChild);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
            try {
                for (var value_1 = __values(value), value_1_1 = value_1.next(); !value_1_1.done; value_1_1 = value_1.next()) {
                    var newChild = value_1_1.value;
                    this.addChild(newChild);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (value_1_1 && !value_1_1.done && (_b = value_1.return)) _b.call(value_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
            this._arrangedChildren = value;
            this.layoutChildren();
        },
        enumerable: false,
        configurable: true
    });
    StackContainer.Group = function (arrangedChildren, groupSize, innerOptions, outerOptions) {
        var groups = Math.ceil(arrangedChildren.length / groupSize);
        var containers = [];
        for (var group = 0; group < groups; group++) {
            var start = group * groupSize;
            var end = Math.min(start + groupSize, arrangedChildren.length);
            var groupChildren = arrangedChildren.slice(start, end);
            var groupContainer = new StackContainer(groupChildren, innerOptions);
            containers.push(groupContainer);
        }
        return new StackContainer(containers, outerOptions);
    };
    StackContainer.prototype.layoutChildren = function () {
        var e_3, _a;
        var previousChild = null;
        try {
            for (var _b = __values(this.arrangedChildren), _c = _b.next(); !_c.done; _c = _b.next()) {
                var child = _c.value;
                if (child.constructor == StackContainer) {
                    child.layoutChildren();
                }
                switch (this._axis) {
                    case STACK_CONTAINER_AXIS.HORIZONTAL:
                        child.position.set(previousChild ? previousChild.x + previousChild.width + this._spacing : 0, (this.calculateMaxHeight() - child.height) * this.alignmentMultiplier());
                        break;
                    case STACK_CONTAINER_AXIS.VERTICAL:
                        child.position.set((this.calculateMaxWidth() - child.width) * this.alignmentMultiplier(), previousChild ? previousChild.y + previousChild.height + this._spacing : 0);
                        break;
                }
                previousChild = child;
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
    };
    StackContainer.prototype.calculateMaxWidth = function () {
        var e_4, _a;
        if (this._minWidth) {
            return this._minWidth;
        }
        var width = 0;
        try {
            for (var _b = __values(this.arrangedChildren), _c = _b.next(); !_c.done; _c = _b.next()) {
                var child = _c.value;
                width = Math.max(child.width, width);
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_4) throw e_4.error; }
        }
        return width;
    };
    StackContainer.prototype.calculateMaxHeight = function () {
        var e_5, _a;
        if (this._minHeight) {
            return this._minHeight;
        }
        var height = 0;
        try {
            for (var _b = __values(this.arrangedChildren), _c = _b.next(); !_c.done; _c = _b.next()) {
                var child = _c.value;
                height = Math.max(child.height, height);
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_5) throw e_5.error; }
        }
        return height;
    };
    StackContainer.prototype.alignmentMultiplier = function () {
        switch (this._alignment) {
            case STACK_CONTAINER_ALIGNMENT.LEADING:
                return 0;
            case STACK_CONTAINER_ALIGNMENT.CENTER:
                return 0.5;
            case STACK_CONTAINER_ALIGNMENT.TRAILING:
                return 1;
        }
    };
    return StackContainer;
}(PIXI.Container));
exports.default = StackContainer;
