"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var pixi_particles_1 = require("pixi-particles");
var ConfettiEmitter = /** @class */ (function (_super) {
    __extends(ConfettiEmitter, _super);
    function ConfettiEmitter() {
        var _this = _super.call(this, new PIXI.Container(), [
            "assets/confetti1.png",
            "assets/confetti2.png",
            "assets/confetti3.png"
        ], {
            "alpha": {
                "start": 1,
                "end": 1
            },
            "scale": {
                "start": 1,
                "end": 1,
                "minimumScaleMultiplier": 0.5
            },
            "color": {
                "start": "#ffffff",
                "end": "#ffffff"
            },
            "speed": {
                "start": 1000,
                "end": 0,
                "minimumSpeedMultiplier": 1
            },
            "acceleration": {
                "x": 0,
                "y": 800
            },
            "maxSpeed": 0,
            "startRotation": {
                "min": -110,
                "max": -70
            },
            "noRotation": false,
            "rotationSpeed": {
                "min": 200,
                "max": 500
            },
            "lifetime": {
                "min": 4,
                "max": 4
            },
            "blendMode": "normal",
            "frequency": 0.05,
            "emitterLifetime": -1,
            "maxParticles": 100,
            "pos": {
                "x": 0,
                "y": 0
            },
            "addAtBack": false,
            "spawnType": "rect",
            "spawnRect": {
                "x": -50,
                "y": -10,
                "w": 100,
                "h": 20
            }
        }) || this;
        _this.emit = false;
        _this.autoUpdate = true;
        return _this;
    }
    return ConfettiEmitter;
}(pixi_particles_1.Emitter));
exports.default = ConfettiEmitter;
