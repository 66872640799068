"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var color_1 = require("../utilities/color");
var TITLE_CONTAINER_MARGIN_X = 22;
var TITLE_CONTAINER_MIN_WIDTH = 170;
var TitleContainer = /** @class */ (function (_super) {
    __extends(TitleContainer, _super);
    function TitleContainer(textures) {
        var _this = _super.call(this) || this;
        _this._textures = textures;
        _this.addChild(_this.background);
        _this.addChild(_this.label);
        return _this;
    }
    Object.defineProperty(TitleContainer.prototype, "background", {
        get: function () {
            if (!this._background) {
                this._background = new PIXI.NineSlicePlane(this._textures["title_background.png"], 28, 0, 28, 0);
                this._background.tint = color_1.COLOR.LIGHT_GREEN;
            }
            return this._background;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TitleContainer.prototype, "label", {
        get: function () {
            if (!this._label) {
                this._label = new PIXI.Text("");
                this._label.style = {
                    fontFamily: "Arial",
                    fontWeight: "bold",
                    fontSize: 25,
                    fill: color_1.COLOR.WHITE
                };
            }
            return this._label;
        },
        enumerable: false,
        configurable: true
    });
    TitleContainer.prototype.setText = function (text) {
        if (this._text == text) {
            return;
        }
        this._text = text;
        this.label.text = text;
        this._background.width = Math.max(this._label.width + TITLE_CONTAINER_MARGIN_X * 2, TITLE_CONTAINER_MIN_WIDTH);
        this._label.position.set((this._background.width - this._label.width) * 0.5, (this._background.height - this._label.height) * 0.5);
    };
    return TitleContainer;
}(PIXI.Container));
exports.default = TitleContainer;
