"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findSlotContainer = exports.adaptPlaceholderSlot = void 0;
function adaptPlaceholderSlot(name, spine) {
    var slotContainer = findSlotContainer(name, spine);
    if (slotContainer) {
        var container = new PIXI.Container();
        container.scale.y = -1;
        slotContainer.removeChildren();
        slotContainer.addChild(container);
        return container;
    }
    return null;
}
exports.adaptPlaceholderSlot = adaptPlaceholderSlot;
function findSlotContainer(name, spine) {
    for (var i = 0; i < spine.skeleton.slots.length; i++) {
        var slot = spine.skeleton.slots[i];
        var slotContainer = spine.slotContainers[i];
        if (slot.data.name == name) {
            return slotContainer;
        }
    }
    return null;
}
exports.findSlotContainer = findSlotContainer;
