"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.METER_START_ENERGY = exports.METER_START_HYGIENE = exports.METER_START_NUTRITION = exports.METER_START_CALMNESS = exports.MAX_STAGE = exports.PET_XP = exports.ROAR_XP = exports.PARK_REACTION_SEND_XP = exports.PARK_REACTION_RECEIVE_XP = exports.TIP_FAVOURITE_XP = exports.WISHLIST_ITEM_XP = exports.RESISTED_CRAVING_XP = exports.DIARY_XP = exports.MISSION_XP = exports.MEMORIZE_MAX_REWARDED_SCORE = exports.MEMORIZE_XP = exports.BREATHE_XP = exports.FEED_XP = exports.CLEAN_XP = exports.BASE_XP = void 0;
exports.BASE_XP = 1000;
exports.CLEAN_XP = 200;
exports.FEED_XP = 100;
exports.BREATHE_XP = 300;
exports.MEMORIZE_XP = 250;
exports.MEMORIZE_MAX_REWARDED_SCORE = 10;
exports.MISSION_XP = 500;
exports.DIARY_XP = 400;
exports.RESISTED_CRAVING_XP = 25;
exports.WISHLIST_ITEM_XP = 350;
exports.TIP_FAVOURITE_XP = 150;
exports.PARK_REACTION_RECEIVE_XP = 50;
exports.PARK_REACTION_SEND_XP = 25;
exports.ROAR_XP = 50;
exports.PET_XP = 50;
exports.MAX_STAGE = 9;
exports.METER_START_CALMNESS = 41;
exports.METER_START_NUTRITION = 53;
exports.METER_START_HYGIENE = 45;
exports.METER_START_ENERGY = 64;
var LEVEL_EXPONENT = 1.5;
var DAY_INVERVAL = 1000 * 60 * 60 * 24;
var STAGE_INTERVAL = DAY_INVERVAL * 7;
var METER_FIRST_DAY_DECAY_RATE = 20;
var METER_DECAY_MAX_DAYS = 7;
var METER_RESET_AFTER_LAPSE = 10;
var METER_LOW_HYGIENE = 50;
var XP_MULTIPLIER_MAX_STEPS = 10;
var XP_MULTIPLIER_STEP = 0.1;
var Calculator = /** @class */ (function () {
    function Calculator() {
    }
    Calculator.daysElapsed = function (from, to) {
        var timeElapsed = to.getTime() - from.getTime();
        return timeElapsed / DAY_INVERVAL;
    };
    Calculator.day = function (from, to) {
        var daysElapsed = this.daysElapsed(from, to);
        var day = daysElapsed >= 0 ? daysElapsed + 1 : daysElapsed;
        return Math.floor(day);
    };
    Calculator.stage = function (from, to) {
        var timeElapsed = to.getTime() - from.getTime();
        var stage = Math.floor(timeElapsed / STAGE_INTERVAL) + 1;
        return Math.min(Math.max(stage, 0), exports.MAX_STAGE);
    };
    Calculator.evolutions = function (stage) {
        return stage - 1;
    };
    Calculator.hatched = function (stage) {
        return stage > 0;
    };
    Calculator.nextStageEvent = function (from, to) {
        var stage = this.stage(from, to);
        if (stage >= exports.MAX_STAGE) {
            return null;
        }
        var eventTime = from.getTime() + stage * STAGE_INTERVAL;
        return new Date(eventTime);
    };
    Calculator.timeUntilNextStageEvent = function (from, to) {
        var event = this.nextStageEvent(from, to);
        if (event == null) {
            return null;
        }
        return event.getTime() - to.getTime();
    };
    Calculator.meterDecay = function (stage, from, to) {
        if (!this.hatched(stage)) {
            return 0;
        }
        var daysElapsed = this.daysElapsed(from, to);
        var decay = METER_FIRST_DAY_DECAY_RATE;
        var cumulativeDecay = 0;
        for (var day = 0; day <= Math.min(daysElapsed, METER_DECAY_MAX_DAYS); day++) {
            var progress = Math.min(daysElapsed - day, 1);
            cumulativeDecay += decay * progress;
            decay *= 0.5;
        }
        return cumulativeDecay;
    };
    Calculator.meterReset = function (from, to, lastSmoked) {
        if (lastSmoked && from < lastSmoked && to > lastSmoked) {
            return METER_RESET_AFTER_LAPSE;
        }
        return null;
    };
    Calculator.recentlyLapsed = function (to, lastSmoked) {
        if (lastSmoked && this.daysElapsed(lastSmoked, to) <= 2) {
            return true;
        }
        return false;
    };
    Calculator.dirty = function (hygiene) {
        return hygiene < METER_LOW_HYGIENE;
    };
    Calculator.levelPercentage = function (level, xp) {
        var minXP = this.xp(level);
        var maxXP = this.xp(level + 1);
        return (xp - minXP) / (maxXP - minXP) * 100;
    };
    Calculator.xp = function (level) {
        return Math.floor(exports.BASE_XP * Math.pow(level, LEVEL_EXPONENT));
    };
    Calculator.level = function (xp) {
        return Math.floor(Math.pow(xp / exports.BASE_XP, 1 / LEVEL_EXPONENT));
    };
    Calculator.xpMultiplier = function (checkinStreak) {
        var steps = Math.min(Math.max(checkinStreak - 1, 0), XP_MULTIPLIER_MAX_STEPS);
        return 1 + (steps * XP_MULTIPLIER_STEP);
    };
    return Calculator;
}());
exports.default = Calculator;
