"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var COLOR_FILTER_SHADER = "\n    float Lum(vec3 color) {\n        return (color.r * 0.3) + (color.g * 0.59) + (color.b * 0.11);\n    }\n\n    vec3 ClipColor(vec3 color) {\n        vec3 result = color;\n        float luminance = Lum(color);\n        float cMin = min(color.r, min(color.g, color.b));\n        float cMax = max(color.r, max(color.g, color.b));\n        if (cMin < 0.0) {\n        result.r = luminance + (((color.r - luminance) * luminance) / (luminance - cMin));\n        result.g = luminance + (((color.g - luminance) * luminance) / (luminance - cMin));\n        result.b = luminance + (((color.b - luminance) * luminance) / (luminance - cMin));\n        }\n        if (cMax > 1.0) {\n        result.r = luminance + (((color.r - luminance) * (1.0 - luminance)) / (cMax - luminance));\n        result.g = luminance + (((color.g - luminance) * (1.0 - luminance)) / (cMax - luminance));\n        result.b = luminance + (((color.b - luminance) * (1.0 - luminance)) / (cMax - luminance));\n        }\n        return result;\n    }\n\n    vec3 SetLum(vec3 color, float luminance) {\n        vec3 result;\n        float diff = luminance - Lum(color);\n        result.r = color.r + diff;\n        result.g = color.g + diff;\n        result.b = color.b + diff;\n        return ClipColor(result);\n    }\n\n    varying vec2 vTextureCoord;\n    uniform sampler2D uSampler;\n    uniform vec3 blendColor;\n\n    void main(void) {\n        vec4 sampleColor = texture2D(uSampler, vTextureCoord);\n        vec3 baseColor = vec3(sampleColor);\n        float luminance = Lum(baseColor);\n        vec3 resultColor = SetLum(blendColor, luminance);\n        gl_FragColor = vec4(resultColor.r, resultColor.g, resultColor.b, sampleColor.a);\n    }\n";
var ColorFilter = /** @class */ (function (_super) {
    __extends(ColorFilter, _super);
    function ColorFilter(color) {
        var _this = this;
        var uniforms = {
            blendColor: PIXI.utils.hex2rgb(color)
        };
        _this = _super.call(this, undefined, COLOR_FILTER_SHADER, uniforms) || this;
        _this._color = color;
        return _this;
    }
    return ColorFilter;
}(PIXI.Filter));
exports.default = ColorFilter;
