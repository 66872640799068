"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
var StateMachine = /** @class */ (function () {
    function StateMachine(states) {
        var e_1, _a;
        try {
            for (var states_1 = __values(states), states_1_1 = states_1.next(); !states_1_1.done; states_1_1 = states_1.next()) {
                var state = states_1_1.value;
                state.stateMachine = this;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (states_1_1 && !states_1_1.done && (_a = states_1.return)) _a.call(states_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this._states = states;
        this._currentState = null;
    }
    Object.defineProperty(StateMachine.prototype, "currentState", {
        get: function () {
            return this._currentState;
        },
        enumerable: false,
        configurable: true
    });
    StateMachine.prototype.transition = function (nextStateClass, onChanged) {
        if (!this.canEnter(nextStateClass)) {
            return null;
        }
        var previousState = this._currentState;
        if (previousState) {
            previousState.willExitTo(nextStateClass);
        }
        this._currentState = this.stateFor(nextStateClass);
        onChanged(this._currentState);
        this._currentState.didEnterFrom(previousState);
        this._currentState.update(0);
        return this._currentState;
    };
    StateMachine.prototype.canEnter = function (nextStateClass) {
        if (!this._currentState) {
            return true;
        }
        return this._currentState.isValidTo(nextStateClass);
    };
    StateMachine.prototype.stateFor = function (stateClass) {
        var e_2, _a;
        try {
            for (var _b = __values(this._states), _c = _b.next(); !_c.done; _c = _b.next()) {
                var state = _c.value;
                if (state.constructor == stateClass) {
                    return state;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return null;
    };
    StateMachine.prototype.enter = function (nextStateClass, prepare) {
        var _this = this;
        this.transition(nextStateClass, function (state) {
            _this._stack = [state];
            prepare === null || prepare === void 0 ? void 0 : prepare(state);
        });
    };
    StateMachine.prototype.push = function (nextStateClass, prepare) {
        var _this = this;
        this.transition(nextStateClass, function (state) {
            _this._stack.push(state);
            prepare === null || prepare === void 0 ? void 0 : prepare(state);
        });
    };
    StateMachine.prototype.pop = function () {
        var _this = this;
        if (this._stack.length <= 1) {
            return;
        }
        var lastState = this._stack[this._stack.length - 2];
        this.transition(lastState.constructor, function (state) {
            _this._stack.pop();
        });
    };
    StateMachine.prototype.update = function (deltaTime) {
        if (!this._currentState) {
            return;
        }
        this._currentState.update(deltaTime);
    };
    return StateMachine;
}());
exports.default = StateMachine;
