"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var color_1 = require("../utilities/color");
var GiftButton = /** @class */ (function (_super) {
    __extends(GiftButton, _super);
    function GiftButton(textures, action) {
        var _this = _super.call(this) || this;
        _this._textures = textures;
        _this._action = action;
        _this.addChild(_this.background);
        _this.addChild(_this.icon);
        _this.interactive = true;
        _this.on("pointerdown", function () {
            action(this);
        });
        return _this;
    }
    Object.defineProperty(GiftButton.prototype, "background", {
        get: function () {
            if (!this._background) {
                this._background = PIXI.Sprite.from(this._textures["gift_button_background.png"]);
                this._background.tint = color_1.COLOR.GREEN;
            }
            return this._background;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(GiftButton.prototype, "icon", {
        get: function () {
            if (!this._icon) {
                this._icon = PIXI.Sprite.from(this._textures["gift.png"]);
                this._icon.position.set((this.background.height - this._icon.height) * 0.5, (this.background.height - this._icon.height) * 0.5);
            }
            return this._icon;
        },
        enumerable: false,
        configurable: true
    });
    return GiftButton;
}(PIXI.Container));
exports.default = GiftButton;
