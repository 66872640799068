"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FOOD_CHOICES = void 0;
exports.FOOD_CHOICES = [
    "meat",
    "apple",
    "fish",
    "bananas",
    "cheese",
    "grapes",
    "carrots",
    "cookies"
];
