"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.NAVIGATION_BUTTON_TYPE = void 0;
var color_1 = require("../utilities/color");
var NAVIGATION_BUTTON_TYPE;
(function (NAVIGATION_BUTTON_TYPE) {
    NAVIGATION_BUTTON_TYPE["BACK"] = "back";
    NAVIGATION_BUTTON_TYPE["SEND"] = "send";
    NAVIGATION_BUTTON_TYPE["INBOX"] = "inbox";
    NAVIGATION_BUTTON_TYPE["CLOSE"] = "close";
})(NAVIGATION_BUTTON_TYPE = exports.NAVIGATION_BUTTON_TYPE || (exports.NAVIGATION_BUTTON_TYPE = {}));
var NavigationButton = /** @class */ (function (_super) {
    __extends(NavigationButton, _super);
    function NavigationButton(textures, type, action) {
        var _this = _super.call(this) || this;
        _this._textures = textures;
        _this._type = type;
        _this._action = action;
        _this.addChild(_this.background);
        _this.addChild(_this.icon);
        _this.interactive = true;
        _this.on("pointerdown", function () {
            action(this);
        });
        return _this;
    }
    Object.defineProperty(NavigationButton.prototype, "background", {
        get: function () {
            if (!this._background) {
                this._background = PIXI.Sprite.from(this._textures["navigation_button_background.png"]);
                this._background.tint = this.color;
            }
            return this._background;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NavigationButton.prototype, "icon", {
        get: function () {
            if (!this._icon) {
                this._icon = PIXI.Sprite.from(this._textures[this._type + ".png"]);
                this._icon.position.set((this.background.height - this._icon.height) * 0.5, (this.background.height - this._icon.height) * 0.5);
            }
            return this._icon;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NavigationButton.prototype, "color", {
        get: function () {
            switch (this._type) {
                case NAVIGATION_BUTTON_TYPE.BACK:
                    return color_1.COLOR.LIGHT_BLUE;
                case NAVIGATION_BUTTON_TYPE.SEND:
                    return color_1.COLOR.LIGHT_PURPLE;
                case NAVIGATION_BUTTON_TYPE.INBOX:
                    return color_1.COLOR.LIGHT_RED;
                default:
                    return color_1.COLOR.BLACK;
            }
        },
        enumerable: false,
        configurable: true
    });
    return NavigationButton;
}(PIXI.Container));
exports.default = NavigationButton;
