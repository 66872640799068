"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var pixi_particles_1 = require("pixi-particles");
var BubbleEmitter = /** @class */ (function (_super) {
    __extends(BubbleEmitter, _super);
    function BubbleEmitter() {
        var _this = _super.call(this, new PIXI.Container(), [
            "assets/bubble.png"
        ], {
            "alpha": {
                "start": 1,
                "end": 1
            },
            "scale": {
                "start": 0.25,
                "end": 0.5,
                "minimumScaleMultiplier": 0.5
            },
            "color": {
                "start": "#ffffff",
                "end": "#ffffff"
            },
            "speed": {
                "start": 80,
                "end": 5,
                "minimumSpeedMultiplier": 1
            },
            "acceleration": {
                "x": 0,
                "y": 0
            },
            "maxSpeed": 0,
            "startRotation": {
                "min": 0,
                "max": 360
            },
            "noRotation": true,
            "rotationSpeed": {
                "min": 0,
                "max": 0
            },
            "lifetime": {
                "min": 2,
                "max": 3.5
            },
            "blendMode": "normal",
            "frequency": 0.4,
            "emitterLifetime": -1,
            "maxParticles": 100,
            "pos": {
                "x": 0,
                "y": 0
            },
            "addAtBack": false,
            "spawnType": "circle",
            "spawnCircle": {
                "x": 0,
                "y": 0,
                "r": 30
            }
        }) || this;
        _this.emit = false;
        _this.autoUpdate = true;
        return _this;
    }
    return BubbleEmitter;
}(pixi_particles_1.Emitter));
exports.default = BubbleEmitter;
