"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var color_1 = require("../utilities/color");
var filter_glow_1 = require("@pixi/filter-glow");
var CategoryButton = /** @class */ (function (_super) {
    __extends(CategoryButton, _super);
    function CategoryButton(textures, categoryName, action) {
        var _this = _super.call(this) || this;
        _this._textures = textures;
        _this._categoryName = categoryName;
        _this._action = action;
        _this.addChild(_this.background);
        _this.addChild(_this.icon);
        _this.interactive = true;
        _this.on("pointerdown", function () {
            action(this);
        });
        _this.locked = false;
        _this.selected = false;
        return _this;
    }
    Object.defineProperty(CategoryButton.prototype, "background", {
        get: function () {
            if (!this._background) {
                this._background = PIXI.Sprite.from(this._textures["category_background.png"]);
                this._background.tint = color_1.COLOR.LIGHT_BLUE;
            }
            return this._background;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CategoryButton.prototype, "icon", {
        get: function () {
            if (!this._icon) {
                this._icon = new PIXI.Sprite();
            }
            return this._icon;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CategoryButton.prototype, "locked", {
        get: function () {
            return this._locked;
        },
        set: function (value) {
            if (this._locked == value) {
                return;
            }
            this._locked = value;
            this.icon.texture = this._textures["category_" + (value ? "locked" : this._categoryName) + ".png"];
            this.icon.position.set((this.background.width - this.icon.width) * 0.5, (this.background.height - this.icon.height) * 0.5);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CategoryButton.prototype, "selected", {
        get: function () {
            return this._selected;
        },
        set: function (value) {
            if (this._selected == value) {
                return;
            }
            this._selected = value;
            if (value) {
                this.background.filters = [new filter_glow_1.GlowFilter({
                        distance: 28,
                        outerStrength: 2.6
                    })];
            }
            else {
                this.background.filters = null;
            }
        },
        enumerable: false,
        configurable: true
    });
    return CategoryButton;
}(PIXI.Container));
exports.default = CategoryButton;
