"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var pixi_particles_1 = require("pixi-particles");
var BreathEmitter = /** @class */ (function (_super) {
    __extends(BreathEmitter, _super);
    function BreathEmitter() {
        return _super.call(this, new PIXI.Container(), null, null) || this;
    }
    BreathEmitter.prototype.configureAppearance = function (breath) {
        if (breath == this._breath) {
            return;
        }
        this._breath = breath;
        var imageNames;
        var startColor;
        var endColor;
        switch (breath) {
            case "yellow_fire":
                imageNames = ["/assets/fire.png"];
                startColor = "#ffff00";
                endColor = "#FF9D00";
                break;
            case "red_fire":
                imageNames = ["/assets/fire.png"];
                startColor = "#FF7400";
                endColor = "#FF0052";
                break;
            case "blue_fire":
                imageNames = ["assets/fire.png"];
                startColor = "#69F8FF";
                endColor = "#3E65E1";
                break;
            case "green_fire":
                imageNames = ["assets/fire.png"];
                startColor = "#ABFF00";
                endColor = "#00C40F";
                break;
            case "snow":
                imageNames = ["assets/snow1.png", "assets/snow2.png", "assets/snow3.png"];
                startColor = "#ffffff";
                endColor = "#ffffff";
                break;
            case "bubbles":
                imageNames = ["assets/bubble.png"];
                startColor = "#ffffff";
                endColor = "#ffffff";
                break;
        }
        this.init(imageNames, {
            "alpha": {
                "start": 1,
                "end": 1
            },
            "scale": {
                "start": 0.85,
                "end": 0.28,
                "minimumScaleMultiplier": 0.7
            },
            "color": {
                "start": startColor,
                "end": endColor
            },
            "speed": {
                "start": 800,
                "end": 0,
                "minimumSpeedMultiplier": 0.7
            },
            "acceleration": {
                "x": -700,
                "y": -428
            },
            "maxSpeed": 0,
            "startRotation": {
                "min": -10,
                "max": 20
            },
            "noRotation": true,
            "rotationSpeed": {
                "min": 0,
                "max": 0
            },
            "lifetime": {
                "min": 1.2,
                "max": 1
            },
            "blendMode": "normal",
            "frequency": 0.01,
            "emitterLifetime": -1,
            "maxParticles": 100,
            "pos": {
                "x": 0,
                "y": 0
            },
            "addAtBack": true,
            "spawnType": "circle",
            "spawnCircle": {
                "x": 0,
                "y": 0,
                "r": 15
            }
        });
        this.emit = false;
        this.autoUpdate = true;
    };
    return BreathEmitter;
}(pixi_particles_1.Emitter));
exports.default = BreathEmitter;
