"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COLOR = void 0;
exports.COLOR = {
    BLACK: 0x000000,
    WHITE: 0xFFFFFF,
    OFF_WHITE: 0xEBECEB,
    GREEN: 0x62AC28,
    LIGHT_GREEN: 0x87D54D,
    DARK_GREEN: 0x26705F,
    LIGHT_RED: 0xF78151,
    LIGHT_YELLOW: 0xEDC948,
    LIGHT_BLUE: 0x3DBBE5,
    NAVY_BLUE: 0x303646,
    LIGHT_PURPLE: 0xAD8BE9,
    PINK: 0xF778A8,
    LIGHT_GRAY: 0xCCCCCC
};
