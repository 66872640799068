"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MESSAGE_NAME = void 0;
var MESSAGE_NAME;
(function (MESSAGE_NAME) {
    MESSAGE_NAME["START"] = "start";
    MESSAGE_NAME["EVENT"] = "event";
    MESSAGE_NAME["REACTION"] = "reaction";
    MESSAGE_NAME["DRAGON"] = "dragon";
    MESSAGE_NAME["QUIT"] = "quit";
    MESSAGE_NAME["ACHIEVEMENTS"] = "achievements";
    MESSAGE_NAME["PARK"] = "park";
    MESSAGE_NAME["INBOX"] = "inbox";
    MESSAGE_NAME["ACTIVITY"] = "activity";
})(MESSAGE_NAME = exports.MESSAGE_NAME || (exports.MESSAGE_NAME = {}));
