"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CUSTOMIZE_CATEGORIES = exports.latestUnlockedCategory = exports.CustomizeCategory = exports.CustomizeElement = void 0;
var CustomizeElement = /** @class */ (function () {
    function CustomizeElement(parameters) {
        this.resourceName = parameters.resourceName;
        this.colorCategory = parameters.colorCategory;
        this.color = parameters.color;
    }
    return CustomizeElement;
}());
exports.CustomizeElement = CustomizeElement;
var CustomizeCategory = /** @class */ (function () {
    function CustomizeCategory(parameters) {
        this.requiredStage = parameters.requiredStage;
        this.elements = parameters.elements;
    }
    return CustomizeCategory;
}());
exports.CustomizeCategory = CustomizeCategory;
function latestUnlockedCategory(stage) {
    var unlockedCategoryIDs = Object.keys(exports.CUSTOMIZE_CATEGORIES)
        .sort(function (a, b) { return exports.CUSTOMIZE_CATEGORIES[a].requiredStage - exports.CUSTOMIZE_CATEGORIES[b].requiredStage; })
        .filter(function (categoryID) { return stage >= exports.CUSTOMIZE_CATEGORIES[categoryID].requiredStage; });
    var categoryID = unlockedCategoryIDs[unlockedCategoryIDs.length - 1];
    return categoryID;
}
exports.latestUnlockedCategory = latestUnlockedCategory;
exports.CUSTOMIZE_CATEGORIES = {
    backdrop: {
        requiredStage: 0,
        elements: {
            beach_cliffs: {},
            crystal_cave: {},
            desert: {},
            emerald_coast: {},
            frozen_north: {},
            meadow: {},
            tropical_cove: {},
            volcano: {}
        }
    },
    body: {
        requiredStage: 1,
        elements: {
            green: {
                resourceName: "base",
                color: 0xB2DE41
            },
            blue: {
                resourceName: "base",
                color: 0x74C5D4
            },
            purple: {
                resourceName: "base",
                color: 0xB98AFF
            },
            pink: {
                resourceName: "base",
                color: 0xFE9AB9
            },
            red: {
                resourceName: "base",
                color: 0xFF8D14
            },
            yellow: {
                resourceName: "base",
                color: 0xF0D209
            },
            gray: {
                resourceName: "base",
                color: 0xDADADA
            },
            cyan: {
                resourceName: "base",
                color: 0x52AC8D
            }
        }
    },
    eyes: {
        requiredStage: 1,
        elements: {
            green: {
                resourceName: "base",
                color: 0x92CB3B
            },
            blue: {
                resourceName: "base",
                color: 0x31AFE1
            },
            purple: {
                resourceName: "base",
                color: 0xB87AF5
            },
            pink: {
                resourceName: "base",
                color: 0xFB5595
            },
            red: {
                resourceName: "base",
                color: 0xFA6D2C
            },
            yellow: {
                resourceName: "base",
                color: 0xFFD63A
            },
            gray: {
                resourceName: "base",
                color: 0xDADADA
            },
            cyan: {
                resourceName: "base",
                color: 0x55CAB0
            }
        }
    },
    head: {
        requiredStage: 2,
        elements: {
            aggressive: {
                colorCategory: "body"
            },
            bone: {
                colorCategory: "body"
            },
            bug: {
                colorCategory: "body"
            },
            crown: {
                colorCategory: "body"
            },
            cute: {
                colorCategory: "body"
            },
            dolphin: {
                colorCategory: "body"
            },
            feather: {
                colorCategory: "body"
            },
            heavy: {
                colorCategory: "body"
            },
            meteor: {
                colorCategory: "body"
            },
            salamander: {
                colorCategory: "body"
            },
            sleek: {
                colorCategory: "body"
            },
            spike: {
                colorCategory: "body"
            },
            star: {
                colorCategory: "body"
            }
        }
    },
    hat: {
        requiredStage: 3,
        elements: {
            none: {},
            gold: {},
            skull: {},
            glasses: {},
            sun: {},
            top: {},
            flower: {},
            trucker: {},
            wizzard: {},
            apple: {},
            can: {},
            cottage: {},
            egg: {}
        }
    },
    scales: {
        requiredStage: 4,
        elements: {
            aggressive: {
                colorCategory: "body"
            },
            cute: {
                colorCategory: "body"
            },
            dolphin: {
                colorCategory: "body"
            },
            heavy: {
                colorCategory: "body"
            },
            meteor: {
                colorCategory: "body"
            },
            salamander: {
                colorCategory: "body"
            },
            sharp: {
                colorCategory: "body"
            },
            sleek: {
                colorCategory: "body"
            }
        }
    },
    collar: {
        requiredStage: 5,
        elements: {
            none: {},
            bow: {},
            bowtie: {},
            flower: {},
            gem_blue: {},
            gem_green: {},
            gem_purple: {},
            gem_red: {},
            bone: {},
            gold: {},
            tooth: {},
            scarf_1: {},
            scarf_2: {},
            spike: {},
            sword: {},
            tie: {},
            leaf: {}
        }
    },
    breath: {
        requiredStage: 6,
        elements: {
            yellow_fire: {
                resourceName: "fire",
                color: 0xFAC926
            },
            red_fire: {
                resourceName: "fire",
                color: 0xF33333
            },
            blue_fire: {
                resourceName: "fire",
                color: 0x2AAEED
            },
            green_fire: {
                resourceName: "fire",
                color: 0x4FEE2D
            },
            snow: {
                color: 0xC8FFFC
            },
            bubbles: {
                color: 0xFFFFFF
            },
        }
    },
    tail: {
        requiredStage: 7,
        elements: {
            aggressive: {
                colorCategory: "body"
            },
            bug: {
                colorCategory: "body"
            },
            crown: {
                colorCategory: "body"
            },
            cute: {
                colorCategory: "body"
            },
            dolphin: {
                colorCategory: "body"
            },
            feather: {
                colorCategory: "body"
            },
            heart: {
                colorCategory: "body"
            },
            heavy: {
                colorCategory: "body"
            },
            meteor: {
                colorCategory: "body"
            },
            salamander: {
                colorCategory: "body"
            },
            sleek: {
                colorCategory: "body"
            },
            spike: {
                colorCategory: "body"
            },
            star: {
                colorCategory: "body"
            }
        }
    },
    band: {
        requiredStage: 8,
        elements: {
            none: {},
            chain: {},
            cloud: {},
            bow: {},
            gold: {},
            leaf: {},
            snake: {},
            flower: {},
            spike: {},
            balloon: {}
        }
    },
    wings: {
        requiredStage: 9,
        elements: {
            small: {
                colorCategory: "body"
            },
            bone: {
                colorCategory: "body"
            },
            bug: {
                colorCategory: "body"
            },
            crown: {
                colorCategory: "body"
            },
            dolphin: {
                colorCategory: "body"
            },
            feather: {
                colorCategory: "body"
            },
            leaf: {
                colorCategory: "body"
            },
            meteor: {
                colorCategory: "body"
            },
            salamander: {
                colorCategory: "body"
            },
            spike: {
                colorCategory: "body"
            },
            star: {
                colorCategory: "body"
            }
        }
    }
};
