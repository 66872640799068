"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var color_1 = require("../utilities/color");
var PROGRESS_BAR_WIDTH = 294;
var ProgressBar = /** @class */ (function (_super) {
    __extends(ProgressBar, _super);
    function ProgressBar() {
        var _this = _super.call(this) || this;
        _this.addChild(_this.background);
        _this.addChild(_this.progress);
        return _this;
    }
    Object.defineProperty(ProgressBar.prototype, "background", {
        get: function () {
            if (!this._background) {
                this._background = new PIXI.Graphics();
                this._background.beginFill(color_1.COLOR.OFF_WHITE);
                this._background.drawRoundedRect(0, 0, PROGRESS_BAR_WIDTH, 7, 3.5);
                this._background.endFill();
            }
            return this._background;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProgressBar.prototype, "progress", {
        get: function () {
            if (!this._progress) {
                this._progress = new PIXI.Graphics();
            }
            return this._progress;
        },
        enumerable: false,
        configurable: true
    });
    ProgressBar.prototype.setPercentage = function (percentage) {
        percentage = Math.max(percentage, 0);
        percentage = Math.min(percentage, 100);
        var progress = percentage / 100;
        this.progress.clear();
        this.progress.beginFill(color_1.COLOR.GREEN);
        this.progress.drawRoundedRect(0, 0, PROGRESS_BAR_WIDTH * progress, 7, 4.5);
        this.progress.endFill();
    };
    return ProgressBar;
}(PIXI.Container));
exports.default = ProgressBar;
