"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuideMessage = exports.GUIDE_MESSAGE_TYPE = void 0;
var GUIDE_MESSAGE_TYPE;
(function (GUIDE_MESSAGE_TYPE) {
    GUIDE_MESSAGE_TYPE[GUIDE_MESSAGE_TYPE["REGULAR"] = 0] = "REGULAR";
    GUIDE_MESSAGE_TYPE[GUIDE_MESSAGE_TYPE["IMPORTANT"] = 1] = "IMPORTANT";
    GUIDE_MESSAGE_TYPE[GUIDE_MESSAGE_TYPE["BONUS"] = 2] = "BONUS";
})(GUIDE_MESSAGE_TYPE = exports.GUIDE_MESSAGE_TYPE || (exports.GUIDE_MESSAGE_TYPE = {}));
var GuideMessage = /** @class */ (function () {
    function GuideMessage(text, type) {
        if (type === void 0) { type = GUIDE_MESSAGE_TYPE.REGULAR; }
        this.text = text;
        this.type = type;
    }
    return GuideMessage;
}());
exports.GuideMessage = GuideMessage;
var Guide = /** @class */ (function () {
    function Guide() {
    }
    Guide.prototype.setPages = function (pages, onCompleted) {
        if (onCompleted === void 0) { onCompleted = null; }
        this.pages = pages;
        this.onCompleted = onCompleted;
    };
    return Guide;
}());
exports.default = Guide;
