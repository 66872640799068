"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var color_1 = require("../utilities/color");
var stack_container_1 = __importStar(require("./stack-container"));
var SCORING_ROW_SPACING = 18;
var SCORING_ROW_VALUE_WIDTH = 94;
var ScoringRow = /** @class */ (function (_super) {
    __extends(ScoringRow, _super);
    function ScoringRow(minWidth) {
        var _this = _super.call(this) || this;
        _this._minWidth = minWidth;
        _this.addChild(_this.stackContainer);
        return _this;
    }
    Object.defineProperty(ScoringRow.prototype, "titleLabel", {
        get: function () {
            if (!this._titleLabel) {
                this._titleLabel = new PIXI.Text("");
                this._titleLabel.style = {
                    fontFamily: "Arial",
                    fontSize: 19,
                    fill: color_1.COLOR.WHITE,
                    wordWrap: true,
                    wordWrapWidth: this._minWidth - SCORING_ROW_SPACING - SCORING_ROW_VALUE_WIDTH
                };
            }
            return this._titleLabel;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScoringRow.prototype, "valueLabel", {
        get: function () {
            if (!this._valueLabel) {
                this._valueLabel = new PIXI.Text("");
                this._valueLabel.style = {
                    fontFamily: "Arial",
                    fontWeight: "bold",
                    fontSize: 25,
                    align: "right",
                    fill: color_1.COLOR.GREEN,
                    wordWrap: true,
                    wordWrapWidth: SCORING_ROW_VALUE_WIDTH
                };
            }
            return this._valueLabel;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScoringRow.prototype, "valueContainer", {
        get: function () {
            if (!this._valueContainer) {
                this._valueContainer = new stack_container_1.default([
                    this.valueLabel
                ], {
                    axis: stack_container_1.STACK_CONTAINER_AXIS.VERTICAL,
                    alignment: stack_container_1.STACK_CONTAINER_ALIGNMENT.TRAILING,
                    spacing: 0,
                    minWidth: SCORING_ROW_VALUE_WIDTH
                });
            }
            return this._valueContainer;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScoringRow.prototype, "stackContainer", {
        get: function () {
            if (!this._stackContainer) {
                this._stackContainer = new stack_container_1.default([
                    this.valueContainer,
                    this.titleLabel
                ], {
                    axis: stack_container_1.STACK_CONTAINER_AXIS.HORIZONTAL,
                    alignment: stack_container_1.STACK_CONTAINER_ALIGNMENT.CENTER,
                    spacing: SCORING_ROW_SPACING
                });
            }
            return this._stackContainer;
        },
        enumerable: false,
        configurable: true
    });
    ScoringRow.prototype.setTitle = function (title, value) {
        this.titleLabel.text = title;
        this.valueLabel.text = value;
        this.stackContainer.layoutChildren();
    };
    return ScoringRow;
}(PIXI.Container));
exports.default = ScoringRow;
