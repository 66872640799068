"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var GESTURE_RECOGNIZER_DRAG_MIN_DISTANCE = 10;
var GESTURE_RECOGNIZER_SCRUB_DURATION = 250;
var GESTURE_RECOGNIZER_LONG_PRESS_DURATION = 1000;
var GestureRecognizer = /** @class */ (function () {
    function GestureRecognizer() {
    }
    Object.defineProperty(GestureRecognizer.prototype, "container", {
        get: function () {
            return this._container;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(GestureRecognizer.prototype, "isScrubbing", {
        get: function () {
            return this._isScrubbing;
        },
        set: function (value) {
            var _a, _b;
            if (this._isScrubbing == value) {
                return;
            }
            this._isScrubbing = value;
            value ? (_a = this._actionScrubBegin) === null || _a === void 0 ? void 0 : _a.call(this) : (_b = this._actionScrubEnd) === null || _b === void 0 ? void 0 : _b.call(this);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(GestureRecognizer.prototype, "touchPosition", {
        get: function () {
            var _a;
            return (_a = this._currentPosition) !== null && _a !== void 0 ? _a : this._startPosition;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(GestureRecognizer.prototype, "totalScrubTime", {
        get: function () {
            return this._totalScrubTime;
        },
        enumerable: false,
        configurable: true
    });
    GestureRecognizer.prototype.onTouchDown = function (value) {
        this._actionTouchDown = value;
        return this;
    };
    GestureRecognizer.prototype.onTouchUp = function (value) {
        this._actionTouchUp = value;
        return this;
    };
    GestureRecognizer.prototype.onScrubBegin = function (value) {
        this._actionScrubBegin = value;
        return this;
    };
    GestureRecognizer.prototype.onScrubEnd = function (value) {
        this._actionScrubEnd = value;
        return this;
    };
    GestureRecognizer.prototype.onLongPress = function (value) {
        this._actionLongPress = value;
        return this;
    };
    GestureRecognizer.prototype.addTo = function (container) {
        this._container = container;
        this._container.on("pointerdown", this.onPress.bind(this));
        this._container.on("pointerup", this.onRelease.bind(this));
        this._container.on("pointerupoutside", this.onRelease.bind(this));
        this._container.on("pointermove", this.onDrag.bind(this));
        this.reset();
    };
    GestureRecognizer.prototype.removeFromContainer = function () {
        this._container.off("pointerdown");
        this._container.off("pointerup");
        this._container.off("pointerupoutside");
        this._container.off("pointermove");
        this._container = null;
    };
    GestureRecognizer.prototype.update = function (deltaTime) {
        if (!this._container.interactive) {
            this.reset();
        }
        if (this._lastPressed == null) {
            return;
        }
        var now = Date.now();
        if (this._lastScrubbed) {
            this.isScrubbing = now - this._lastScrubbed <= GESTURE_RECOGNIZER_SCRUB_DURATION;
            if (this.isScrubbing) {
                this._totalScrubTime += deltaTime;
            }
        }
        else if (this._actionLongPress && now - this._lastPressed >= GESTURE_RECOGNIZER_LONG_PRESS_DURATION) {
            this.reset();
            this._actionLongPress();
        }
    };
    GestureRecognizer.prototype.reset = function () {
        this._startPosition = null;
        this._currentPosition = null;
        this._lastPressed = null;
        this._lastScrubbed = null;
        this.isScrubbing = false;
        this._totalScrubTime = 0;
    };
    GestureRecognizer.prototype.onPress = function (event) {
        var _a;
        this._startPosition = event.data.getLocalPosition(this._container);
        this._lastPressed = Date.now();
        (_a = this._actionTouchDown) === null || _a === void 0 ? void 0 : _a.call(this);
    };
    GestureRecognizer.prototype.onRelease = function () {
        var _a;
        (_a = this._actionTouchUp) === null || _a === void 0 ? void 0 : _a.call(this);
        this.reset();
    };
    GestureRecognizer.prototype.onDrag = function (event) {
        if (this._lastPressed == null) {
            return;
        }
        this._currentPosition = event.data.getLocalPosition(this._container);
        var vector = new PIXI.Point(this._currentPosition.x - this._startPosition.x, this._currentPosition.y - this._startPosition.y);
        var distance = Math.sqrt(vector.x * vector.x + vector.y * vector.y);
        if (distance >= GESTURE_RECOGNIZER_DRAG_MIN_DISTANCE) {
            this._startPosition = this._currentPosition;
            this._lastScrubbed = Date.now();
        }
    };
    return GestureRecognizer;
}());
exports.default = GestureRecognizer;
