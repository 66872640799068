"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var activity_1 = require("../models/activity");
var game_area_1 = require("../utilities/game-area");
var color_1 = require("../utilities/color");
var ActivityButton = /** @class */ (function (_super) {
    __extends(ActivityButton, _super);
    function ActivityButton(textures, type, action) {
        var _this = _super.call(this) || this;
        _this._textures = textures;
        _this._type = type;
        _this._action = action;
        _this.addChild(_this.background);
        _this.addChild(_this.icon);
        _this.interactive = true;
        _this.on("pointerdown", function () {
            action(this);
        });
        _this.active = true;
        return _this;
    }
    Object.defineProperty(ActivityButton.prototype, "background", {
        get: function () {
            if (!this._background) {
                this._background = new PIXI.NineSlicePlane(this._textures["activity_background.png"], 69, 0, 0, 0);
                this._background.width = 70 + game_area_1.GAME_PADDING_X;
            }
            return this._background;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ActivityButton.prototype, "icon", {
        get: function () {
            if (!this._icon) {
                this._icon = PIXI.Sprite.from(this._textures["activity_" + this._type + ".png"]);
                this._icon.position.set(70 - this.icon.width - 8, (this.background.height - this._icon.height) * 0.5);
            }
            return this._icon;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ActivityButton.prototype, "color", {
        get: function () {
            switch (this._type) {
                case activity_1.ACTIVITY_TYPE.INBOX:
                    return color_1.COLOR.LIGHT_RED;
                case activity_1.ACTIVITY_TYPE.PARK:
                    return color_1.COLOR.LIGHT_GREEN;
                case activity_1.ACTIVITY_TYPE.BREATHE:
                    return color_1.COLOR.PINK;
                case activity_1.ACTIVITY_TYPE.MINIGAMES:
                    return color_1.COLOR.LIGHT_PURPLE;
                case activity_1.ACTIVITY_TYPE.CLEAN:
                    return color_1.COLOR.LIGHT_BLUE;
                case activity_1.ACTIVITY_TYPE.FEED:
                    return color_1.COLOR.LIGHT_YELLOW;
                case activity_1.ACTIVITY_TYPE.CUSTOMIZE:
                    return color_1.COLOR.LIGHT_RED;
                default:
                    return color_1.COLOR.BLACK;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ActivityButton.prototype, "active", {
        get: function () {
            return this._active;
        },
        set: function (value) {
            if (this._active == value) {
                return;
            }
            this._active = value;
            this.background.tint = value ? this.color : color_1.COLOR.LIGHT_GRAY;
        },
        enumerable: false,
        configurable: true
    });
    return ActivityButton;
}(PIXI.Container));
exports.default = ActivityButton;
