"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MESSAGE_BUTTON_STYLE = void 0;
var color_1 = require("../utilities/color");
var MESSAGE_BUTTON_STYLE;
(function (MESSAGE_BUTTON_STYLE) {
    MESSAGE_BUTTON_STYLE[MESSAGE_BUTTON_STYLE["REGULAR"] = 0] = "REGULAR";
    MESSAGE_BUTTON_STYLE[MESSAGE_BUTTON_STYLE["ACCENT"] = 1] = "ACCENT";
    MESSAGE_BUTTON_STYLE[MESSAGE_BUTTON_STYLE["EMOJI"] = 2] = "EMOJI";
    MESSAGE_BUTTON_STYLE[MESSAGE_BUTTON_STYLE["EMOJI_LARGE"] = 3] = "EMOJI_LARGE";
})(MESSAGE_BUTTON_STYLE = exports.MESSAGE_BUTTON_STYLE || (exports.MESSAGE_BUTTON_STYLE = {}));
var MessageButton = /** @class */ (function (_super) {
    __extends(MessageButton, _super);
    function MessageButton(textures, action) {
        var _this = _super.call(this) || this;
        _this._textures = textures;
        _this._action = action;
        _this.addChild(_this.background);
        _this.addChild(_this.label);
        _this.interactive = true;
        _this.on("pointerdown", function () {
            action(this);
        });
        return _this;
    }
    Object.defineProperty(MessageButton.prototype, "background", {
        get: function () {
            if (!this._background) {
                this._background = new PIXI.NineSlicePlane(this._textures["message_background.png"], 21, 0, 21, 0);
            }
            return this._background;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MessageButton.prototype, "label", {
        get: function () {
            if (!this._label) {
                this._label = new PIXI.Text("");
                this._label.style = {
                    fontFamily: "Arial",
                    fontWeight: "bold"
                };
            }
            return this._label;
        },
        enumerable: false,
        configurable: true
    });
    MessageButton.prototype.setText = function (text, style) {
        if (style === void 0) { style = MESSAGE_BUTTON_STYLE.REGULAR; }
        if (this._text == text && this._style == style) {
            return;
        }
        this._text = text;
        this._style = style;
        var fontSize;
        var textColor;
        var backgroundColor;
        var backgroundAlpha;
        var marginX;
        var marginY;
        switch (style) {
            case MESSAGE_BUTTON_STYLE.REGULAR:
                fontSize = 19;
                textColor = color_1.COLOR.BLACK;
                backgroundColor = color_1.COLOR.WHITE;
                backgroundAlpha = 1;
                marginX = 22;
                marginY = 10;
                break;
            case MESSAGE_BUTTON_STYLE.ACCENT:
                fontSize = 19;
                textColor = color_1.COLOR.WHITE;
                backgroundColor = color_1.COLOR.LIGHT_GREEN;
                backgroundAlpha = 1;
                marginX = 22;
                marginY = 10;
                break;
            case MESSAGE_BUTTON_STYLE.EMOJI:
                fontSize = 42;
                textColor = color_1.COLOR.WHITE;
                backgroundColor = color_1.COLOR.BLACK;
                backgroundAlpha = 0;
                marginX = 12;
                marginY = 0;
                break;
            case MESSAGE_BUTTON_STYLE.EMOJI_LARGE:
                fontSize = 60;
                textColor = color_1.COLOR.WHITE;
                backgroundColor = color_1.COLOR.BLACK;
                backgroundAlpha = 0;
                marginX = 17;
                marginY = 13;
                break;
        }
        this._label.style.fontSize = fontSize;
        this._label.style.fill = textColor;
        this._label.text = text;
        this._background.tint = backgroundColor;
        this._background.alpha = backgroundAlpha;
        this._background.width = this._label.width + marginX * 2;
        this._background.height = this._label.height + marginY * 2;
        this._label.position.set((this._background.width - this._label.width) * 0.5, (this._background.height - this._label.height) * 0.5);
    };
    return MessageButton;
}(PIXI.Container));
exports.default = MessageButton;
