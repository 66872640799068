"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REACTION_CATEGORIES = void 0;
exports.REACTION_CATEGORIES = {
    message: [
        "do_not_give_up",
        "cool_dragon",
        "come_so_far",
        "keep_it_up",
        "doing_great",
        "well_done",
        "amazing_progress",
        "you_can_do_it",
        "rooting_for_you",
        "welcome_newcomer",
        "earned_this",
        "you_are_flying",
        "look_at_you_go",
        "in_this_together",
        "unstoppable",
        "complete_a_mission"
    ],
    emoji: [
        "money_mouth_face",
        "red_heart",
        "party_face",
        "thumbs_up",
        "smiling_face_with_sunglasses",
        "clapping_hands",
        "face_with_starry_eyes",
        "hugging_face",
        "goofy_face",
        "grinning_face_with_squinting_eyes",
        "waving_hand",
        "winking_face",
        "surprised_face_with_open_mouth",
        "handshake",
        "star",
        "grinning_face_with_smiling_eyes",
        "praise"
    ]
};
