"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SHEET_TYPE = void 0;
var color_1 = require("../utilities/color");
var game_area_1 = require("../utilities/game-area");
var gsap_1 = require("gsap");
var PixiPlugin_1 = require("gsap/PixiPlugin");
gsap_1.gsap.registerPlugin(PixiPlugin_1.PixiPlugin);
PixiPlugin_1.PixiPlugin.registerPIXI(PIXI);
var SHEET_CONTENT_MARGIN_TOP = 50;
var SHEET_CONTENT_MARGIN_BOTTOM = 4;
var SHEET_TYPE;
(function (SHEET_TYPE) {
    SHEET_TYPE[SHEET_TYPE["LIGHT"] = 0] = "LIGHT";
    SHEET_TYPE[SHEET_TYPE["DARK"] = 1] = "DARK";
})(SHEET_TYPE = exports.SHEET_TYPE || (exports.SHEET_TYPE = {}));
var Sheet = /** @class */ (function (_super) {
    __extends(Sheet, _super);
    function Sheet(textures, type) {
        if (type === void 0) { type = SHEET_TYPE.LIGHT; }
        var _this = _super.call(this) || this;
        _this._textures = textures;
        _this._type = type;
        _this.addChild(_this.background);
        _this.addChild(_this.contentContainer);
        return _this;
    }
    Object.defineProperty(Sheet.prototype, "contentContainer", {
        get: function () {
            if (!this._contentContainer) {
                this._contentContainer = new PIXI.Container();
                this._contentContainer.position.set(0, SHEET_CONTENT_MARGIN_TOP);
            }
            return this._contentContainer;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Sheet.prototype, "background", {
        get: function () {
            if (!this._background) {
                switch (this._type) {
                    case SHEET_TYPE.LIGHT:
                        var light = new PIXI.NineSlicePlane(this._textures["sheet_light_background.png"], 0, 318, 0, 0);
                        light.tint = color_1.COLOR.DARK_GREEN;
                        this._background = light;
                        break;
                    case SHEET_TYPE.DARK:
                        var dark = new PIXI.NineSlicePlane(this._textures["sheet_dark_background.png"], 0, 348, 0, 0);
                        dark.tint = color_1.COLOR.BLACK;
                        dark.alpha = 0.7;
                        this._background = dark;
                        break;
                    default:
                        this._background = new PIXI.Sprite();
                        break;
                }
                this._background.width = game_area_1.GAME_WIDTH;
                this._background.height = game_area_1.GAME_HEIGHT;
            }
            return this._background;
        },
        enumerable: false,
        configurable: true
    });
    Sheet.prototype.show = function (visible, animated, onComplete) {
        var height = SHEET_CONTENT_MARGIN_TOP + this.contentContainer.height + SHEET_CONTENT_MARGIN_BOTTOM + game_area_1.GAME_PADDING_Y;
        var y = game_area_1.GAME_HEIGHT - (visible ? height : 0);
        this.interactiveChildren = visible;
        if (animated) {
            gsap_1.gsap.to(this, {
                duration: 0.5,
                y: y,
                ease: "Power1.easeInOut",
                onComplete: onComplete
            });
        }
        else {
            this.y = y;
        }
    };
    return Sheet;
}(PIXI.Container));
exports.default = Sheet;
