"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
require("pixi-spine");
var placeholder_slot_1 = require("../utilities/placeholder-slot");
var ParkContainer = /** @class */ (function (_super) {
    __extends(ParkContainer, _super);
    function ParkContainer(resources) {
        var _this = _super.call(this) || this;
        _this._resources = resources;
        _this._animationListeners = new Set();
        return _this;
    }
    Object.defineProperty(ParkContainer.prototype, "spine", {
        get: function () {
            if (!this._spine) {
                this._spine = new PIXI.spine.Spine(this._resources.park.spineData);
                this._spine.autoUpdate = false;
                this.addChild(this._spine);
                // Load slots
                this.dragonSlot;
                this.dragonCurrentSlot;
                this.dragonNextSlot;
                this.dragonPreviousSlot;
            }
            return this._spine;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ParkContainer.prototype, "dragonSlot", {
        get: function () {
            if (!this._dragonSlot) {
                this._dragonSlot = (0, placeholder_slot_1.adaptPlaceholderSlot)("dragon_self", this.spine);
            }
            return this._dragonSlot;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ParkContainer.prototype, "dragonCurrentSlot", {
        get: function () {
            if (!this._dragonCurrentSlot) {
                this._dragonCurrentSlot = (0, placeholder_slot_1.adaptPlaceholderSlot)("dragon_current", this.spine);
            }
            return this._dragonCurrentSlot;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ParkContainer.prototype, "dragonNextSlot", {
        get: function () {
            if (!this._dragonNextSlot) {
                this._dragonNextSlot = (0, placeholder_slot_1.adaptPlaceholderSlot)("dragon_next", this.spine);
            }
            return this._dragonNextSlot;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ParkContainer.prototype, "dragonPreviousSlot", {
        get: function () {
            if (!this._dragonPreviousSlot) {
                this._dragonPreviousSlot = (0, placeholder_slot_1.adaptPlaceholderSlot)("dragon_previous", this.spine);
            }
            return this._dragonPreviousSlot;
        },
        enumerable: false,
        configurable: true
    });
    ParkContainer.prototype.setCycleAnimation = function (direction, complete) {
        var _a;
        var animationName = "cycle_" + direction;
        if ((_a = this.spine.state.getCurrent(0)) === null || _a === void 0 ? void 0 : _a.animation.name.startsWith("cycle_")) {
            return;
        }
        var entry = this.spine.state.setAnimation(0, animationName, false);
        entry.listener = {
            complete: function (entry) {
                complete();
            }
        };
        this.spine.state.addEmptyAnimation(0, 0, 0);
    };
    return ParkContainer;
}(PIXI.Container));
exports.default = ParkContainer;
