"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SCORING_INFO = void 0;
var calculator_1 = require("./calculator");
exports.SCORING_INFO = {
    clean: {
        xp: calculator_1.CLEAN_XP,
        replace: {}
    },
    feed: {
        xp: calculator_1.FEED_XP,
        replace: {}
    },
    breathe: {
        xp: calculator_1.BREATHE_XP,
        replace: {}
    },
    memorize: {
        xp: calculator_1.MEMORIZE_XP,
        replace: {
            "[memorize_max_score]": String(calculator_1.MEMORIZE_MAX_REWARDED_SCORE)
        }
    },
    mission: {
        xp: calculator_1.MISSION_XP,
        replace: {}
    },
    diary: {
        xp: calculator_1.DIARY_XP,
        replace: {}
    },
    craving: {
        xp: calculator_1.RESISTED_CRAVING_XP,
        replace: {}
    },
    wishlist_item: {
        xp: calculator_1.WISHLIST_ITEM_XP,
        replace: {}
    },
    tip_favourite: {
        xp: calculator_1.TIP_FAVOURITE_XP,
        replace: {}
    },
    park_reaction_receive: {
        xp: calculator_1.PARK_REACTION_RECEIVE_XP,
        replace: {}
    },
    park_reaction_send: {
        xp: calculator_1.PARK_REACTION_SEND_XP,
        replace: {}
    },
    roar: {
        xp: calculator_1.ROAR_XP,
        replace: {}
    },
    pet: {
        xp: calculator_1.PET_XP,
        replace: {}
    }
};
