"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ACTIVITY_TYPE = void 0;
var ACTIVITY_TYPE;
(function (ACTIVITY_TYPE) {
    ACTIVITY_TYPE["INBOX"] = "inbox";
    ACTIVITY_TYPE["PARK"] = "park";
    ACTIVITY_TYPE["BREATHE"] = "breathe";
    ACTIVITY_TYPE["MINIGAMES"] = "minigames";
    ACTIVITY_TYPE["CLEAN"] = "clean";
    ACTIVITY_TYPE["FEED"] = "feed";
    ACTIVITY_TYPE["CUSTOMIZE"] = "customize";
    ACTIVITY_TYPE["PET"] = "pet";
})(ACTIVITY_TYPE = exports.ACTIVITY_TYPE || (exports.ACTIVITY_TYPE = {}));
