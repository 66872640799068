"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
require("pixi-spine");
var placeholder_slot_1 = require("../utilities/placeholder-slot");
var GiftContainer = /** @class */ (function (_super) {
    __extends(GiftContainer, _super);
    function GiftContainer(resources) {
        var _this = _super.call(this) || this;
        _this._resources = resources;
        return _this;
    }
    Object.defineProperty(GiftContainer.prototype, "spine", {
        get: function () {
            if (!this._spine) {
                this._spine = new PIXI.spine.Spine(this._resources.gift.spineData);
                // this._spine.autoUpdate = false;
                this.addChild(this._spine);
                // Load slots
                this.confettiSlot;
                this.itemSlot;
            }
            return this._spine;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(GiftContainer.prototype, "confettiSlot", {
        get: function () {
            if (!this._confettiSlot) {
                this._confettiSlot = (0, placeholder_slot_1.adaptPlaceholderSlot)("confetti", this.spine);
            }
            return this._confettiSlot;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(GiftContainer.prototype, "itemSlot", {
        get: function () {
            if (!this._itemSlot) {
                this._itemSlot = (0, placeholder_slot_1.adaptPlaceholderSlot)("item", this.spine);
            }
            return this._itemSlot;
        },
        enumerable: false,
        configurable: true
    });
    GiftContainer.prototype.setIdleAnimation = function () {
        var _a;
        if (((_a = this.spine.state.getCurrent(0)) === null || _a === void 0 ? void 0 : _a.animation.name) == "idle") {
            return;
        }
        this.spine.state.setAnimation(0, "idle", true);
    };
    GiftContainer.prototype.setOpenAnimation = function (confetti, complete) {
        var _a;
        if (((_a = this.spine.state.getCurrent(0)) === null || _a === void 0 ? void 0 : _a.animation.name) == "open") {
            return;
        }
        var entry = this.spine.state.setAnimation(0, "open", false);
        entry.mixDuration = 0.5;
        entry.listener = {
            event: function (entry, event) {
                if (event.data.name == "confetti_on") {
                    confetti(true);
                }
                else if (event.data.name == "confetti_off") {
                    confetti(false);
                }
            },
            complete: function (entry) {
                complete();
            }
        };
    };
    return GiftContainer;
}(PIXI.Container));
exports.default = GiftContainer;
