"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var color_1 = require("../utilities/color");
var PROGRESS_RING_RADIUS = 31;
var ProgressRing = /** @class */ (function (_super) {
    __extends(ProgressRing, _super);
    function ProgressRing() {
        var _this = _super.call(this) || this;
        _this.addChild(_this.background);
        _this.addChild(_this.progress);
        _this.addChild(_this.label);
        _this.percentage = 0;
        return _this;
    }
    Object.defineProperty(ProgressRing.prototype, "background", {
        get: function () {
            if (!this._background) {
                this._background = new PIXI.Graphics();
            }
            return this._background;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProgressRing.prototype, "progress", {
        get: function () {
            if (!this._progress) {
                this._progress = new PIXI.Graphics();
            }
            return this._progress;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProgressRing.prototype, "label", {
        get: function () {
            if (!this._label) {
                this._label = new PIXI.Text("");
                this._label.style = {
                    fontFamily: "Arial",
                    fontSize: 20,
                    fill: color_1.COLOR.WHITE
                };
                this._label.anchor.set(0.5);
                this._label.position.set(PROGRESS_RING_RADIUS, PROGRESS_RING_RADIUS);
            }
            return this._label;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProgressRing.prototype, "percentage", {
        get: function () {
            return this._percentage;
        },
        set: function (value) {
            if (this._percentage == value) {
                return;
            }
            if (value !== null) {
                value = Math.max(value, 0);
                value = Math.min(value, 100);
            }
            this._percentage = value;
            this.updateBackground();
            this.updateProgress();
            this.updateLabel();
        },
        enumerable: false,
        configurable: true
    });
    ProgressRing.prototype.updateBackground = function () {
        this.background.clear();
        this.background.beginFill(this.percentage === null ? color_1.COLOR.LIGHT_GRAY : color_1.COLOR.NAVY_BLUE);
        this.background.lineStyle(6, color_1.COLOR.OFF_WHITE, 1);
        this.background.drawCircle(PROGRESS_RING_RADIUS, PROGRESS_RING_RADIUS, PROGRESS_RING_RADIUS);
        this.background.endFill();
    };
    ProgressRing.prototype.updateProgress = function () {
        this.progress.clear();
        if (this.percentage === null) {
            return;
        }
        var progress = this.percentage / 100;
        var startAngle = Math.PI * -0.5;
        var endAngle = startAngle + (2 * Math.PI * progress);
        this.progress.lineStyle(7, color_1.COLOR.GREEN);
        this.progress.arc(PROGRESS_RING_RADIUS, PROGRESS_RING_RADIUS, PROGRESS_RING_RADIUS, startAngle, endAngle);
    };
    ProgressRing.prototype.updateLabel = function () {
        if (this.percentage === null) {
            this.label.text = "";
            return;
        }
        this.label.text = Math.round(this.percentage) + "%";
    };
    return ProgressRing;
}(PIXI.Container));
exports.default = ProgressRing;
