"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
require("pixi-spine");
var placeholder_slot_1 = require("../utilities/placeholder-slot");
var MemorizeContainer = /** @class */ (function (_super) {
    __extends(MemorizeContainer, _super);
    function MemorizeContainer(resources) {
        var _this = _super.call(this) || this;
        _this._resources = resources;
        return _this;
    }
    Object.defineProperty(MemorizeContainer.prototype, "spine", {
        get: function () {
            if (!this._spine) {
                this._spine = new PIXI.spine.Spine(this._resources.memorize.spineData);
                this._spine.autoUpdate = false;
                this._spine.on("pointerdown", this.onSpine.bind(this));
                this.addChild(this._spine);
                // Load slots
                this.dragonSlot;
            }
            return this._spine;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MemorizeContainer.prototype, "dragonSlot", {
        get: function () {
            if (!this._dragonSlot) {
                this._dragonSlot = (0, placeholder_slot_1.adaptPlaceholderSlot)("dragon", this.spine);
            }
            return this._dragonSlot;
        },
        enumerable: false,
        configurable: true
    });
    MemorizeContainer.prototype.clearAnimations = function () {
        this.spine.state.setEmptyAnimation(0, 0);
    };
    MemorizeContainer.prototype.addFlashAnimation = function (colorName) {
        var entry = this.spine.state.addAnimation(0, "flash_" + colorName, false, 0);
        entry.trackEnd = entry.animation.duration;
    };
    MemorizeContainer.prototype.addEmptyAnimation = function (delay, complete) {
        if (complete === void 0) { complete = null; }
        var entry = this.spine.state.addEmptyAnimation(0, 0, delay);
        entry.listener = {
            complete: function (entry) {
                if (complete) {
                    complete();
                }
            }
        };
    };
    MemorizeContainer.prototype.setGameOverAnimation = function () {
        var entry = this.spine.state.setAnimation(0, "game_over", false);
        entry.trackEnd = entry.animation.duration;
    };
    MemorizeContainer.prototype.onSpine = function (event) {
        var point = event.data.getLocalPosition(this.spine);
        var skeletonBounds = new PIXI.spine.core.SkeletonBounds();
        skeletonBounds.update(this.spine.skeleton, false);
        var hitBox = skeletonBounds.containsPoint(point.x, point.y);
        if (hitBox) {
            this.emit("hitbox", hitBox);
        }
    };
    return MemorizeContainer;
}(PIXI.Container));
exports.default = MemorizeContainer;
