"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.randomValues = exports.randomValue = exports.randomNumber = void 0;
function randomNumber(max) {
    return Math.floor(Math.random() * max);
}
exports.randomNumber = randomNumber;
function randomValue(array) {
    var index = randomNumber(array.length);
    return array[index];
}
exports.randomValue = randomValue;
function randomValues(array, length) {
    var remaining = __spreadArray([], __read(array), false);
    var values = [];
    for (var i = 0; i < Math.min(array.length, length); i++) {
        var index = randomNumber(remaining.length);
        var value = remaining[index];
        remaining.splice(index, 1);
        values.push(value);
    }
    return values;
}
exports.randomValues = randomValues;
